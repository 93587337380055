import Upload from "app/components/atoms/upload";
import CustomTextBox from "app/components/atoms/customTextBox";
import CustomCheckBox from "app/components/atoms/customCheckBox";
import CustomRadio from "app/components/atoms/customRadio";
import CustomDate from "app/components/atoms/customDate";
import CustomSelect from "app/components/atoms/customSelect";
import CustomButton from "app/components/atoms/customButton";
import MultipleSelect from "app/components/atoms/multiDropdown/MultipleSelect.mui";

const CustomUpload = ({ getProps }) => {
  return (
    <Upload
      recordType={getProps.schema.containerName}
      recordPath={getProps.schema.recordPath}
      getProps={getProps}
    />
  );
};

export const uiSchema = {
  "ui:title": "",

  idOrPassportDoc: {
    "ui:widget": (props) => {
      return <CustomUpload getProps={props} />;
    },
  },
  title: {
    "ui:widget": (props) => {
      return <CustomSelect getProps={props} />;
    },
  },
  surname: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  firstName: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  secondName: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  dateofbirth: {
    "ui:widget": (props) => {
      return <CustomDate getProps={props} />;
    },
  },
  citizenofsaConditional: {
    citizenRadio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    typeOfIdentity: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
    citizenid: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  nationality: {
    "ui:widget": (props) => {
      return <CustomSelect getProps={props} />;
    },
  },
  race: {
    "ui:widget": (props) => {
      return <CustomSelect getProps={props} />;
    },
  },
  gender: {
    "ui:widget": (props) => {
      return <CustomSelect getProps={props} />;
    },
  },
  disabilityConditional: {
    disability: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
    othersspecify: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  nextOfKin: {
    surname: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    firstName: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    secondName: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    email: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    mobileNumber: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    relationshipConditional: {
      relationship: {
        "ui:widget": (props) => {
          return <CustomSelect getProps={props} />;
        },
      },
      othersspecify: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
    },
  },

  contactDetails: {
    email: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    mobileNumber: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    altContactNumber: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    homeContactNumber: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  // residentialAddress: {
  //   languagePreference: {
  //     "ui:widget": (props) => {
  //       return <CustomRadio getProps={props} />;
  //     },
  //   },
  //   addressLine1: {
  //     "ui:widget": (props) => {
  //       return <CustomTextBox getProps={props} />;
  //     },
  //   },
  //   addressLine2: {
  //     "ui:widget": (props) => {
  //       return <CustomTextBox getProps={props} />;
  //     },
  //   },
  //   suburb: {
  //     "ui:widget": (props) => {
  //       return <CustomTextBox getProps={props} />;
  //     },
  //   },
  //   City: {
  //     "ui:widget": (props) => {
  //       return <CustomTextBox getProps={props} />;
  //     },
  //   },
  //   postalCode: {
  //     "ui:widget": (props) => {
  //       return <CustomTextBox getProps={props} />;
  //     },
  //   },
  //   country: {
  //     "ui:widget": (props) => {
  //       return <CustomSelect getProps={props} />;
  //     },
  //   },
  // },
  // postaladdress: {
  //   sameAsAboveConditional: {
  //     sameAsAboveRadio: {
  //       "ui:widget": (props) => {
  //         return <CustomRadio getProps={props} />;
  //       },
  //     },
  //     addressLine1: {
  //       "ui:widget": (props) => {
  //         return <CustomTextBox getProps={props} />;
  //       },
  //     },
  //     addressLine2: {
  //       "ui:widget": (props) => {
  //         return <CustomTextBox getProps={props} />;
  //       },
  //     },
  //     suburb: {
  //       "ui:widget": (props) => {
  //         return <CustomTextBox getProps={props} />;
  //       },
  //     },
  //     City: {
  //       "ui:widget": (props) => {
  //         return <CustomTextBox getProps={props} />;
  //       },
  //     },
  //     postalCode: {
  //       "ui:widget": (props) => {
  //         return <CustomTextBox getProps={props} />;
  //       },
  //     },
  //     country: {
  //       "ui:widget": (props) => {
  //         return <CustomSelect getProps={props} />;
  //       },
  //     },
  //   },
  // },

  resAddressLine1: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  resAddressLine2: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  resSuburb: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  resCity: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  resPostalCode: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  resCountry: {
    "ui:widget": (props) => {
      return <CustomSelect getProps={props} />;
    },
  },
  addressConditional: {
    addressRadio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    posAddressLine1: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    posAddressLine2: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    posSuburb: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    posCity: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    posPostalCode: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    posCountry: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
  },
  tertiaryInstitution: {
    "ui:widget": (props) => {
      return <CustomSelect getProps={props} />;
    },
  },
  campus: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  nameOfDegree: {
    "ui:widget": (props) => {
      return <CustomSelect getProps={props} />;
    },
  },
  descipline: {
    "ui:widget": (props) => {
      return <CustomSelect getProps={props} />;
    },
  },
  diplomaConditional: {
    diplomaConditionalRadio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    universityRegistrationNumber: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    institutionConditional: {
      tertiaryInstitution: {
        "ui:widget": (props) => {
          return <CustomSelect getProps={props} />;
        },
      },
      otherInstitution: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
      branchConditional1: {
        branchSelect: {
          "ui:widget": (props) => {
            return <CustomSelect getProps={props} />;
          },
        },
        branch: {
          "ui:widget": (props) => {
            return <CustomSelect getProps={props} />;
          },
        },
      },
      branchConditional2: {
        branchSelect: {
          "ui:widget": (props) => {
            return <CustomSelect getProps={props} />;
          },
        },
        branch: {
          "ui:widget": (props) => {
            return <CustomSelect getProps={props} />;
          },
        },
      },
    },
    nameOfDegreeConditional: {
      nameOfDegree: {
        "ui:widget": (props) => {
          return <CustomSelect getProps={props} />;
        },
      },
      otherNameOfDegree: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
    },
    desciplineConditional: {
      descipline: {
        "ui:widget": (props) => {
          return <CustomSelect getProps={props} />;
        },
      },
      otherDescipline: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
    },
    currentYearOfStudy: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
    semester: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
    estimatedYearOfCompletion: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
    nameOfQualificationConditional: {
      nameOfQualification: {
        "ui:widget": (props) => {
          return <CustomSelect getProps={props} />;
        },
      },
      othernameOfQualification: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
    },
    dateObtained: {
      "ui:widget": (props) => {
        return <CustomDate getProps={props} />;
      },
    },
    country: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
    degreeCertificate: {
      "ui:widget": (props) => {
        return <CustomUpload getProps={props} />;
      },
    },
    addAnothertertiaryEducation: {
      items: {
        educationalInstitution: {
          "ui:widget": (props) => {
            return <CustomSelect getProps={props} />;
          },
        },
        institutionConditional: {
          tertiaryInstitution: {
            "ui:widget": (props) => {
              return <CustomSelect getProps={props} />;
            },
          },
          otherInstitution: {
            "ui:widget": (props) => {
              return <CustomTextBox getProps={props} />;
            },
          },
        },

        nameOfQualificationConditional: {
          nameOfQualification: {
            "ui:widget": (props) => {
              return <CustomSelect getProps={props} />;
            },
          },
          othernameOfQualification: {
            "ui:widget": (props) => {
              return <CustomTextBox getProps={props} />;
            },
          },
        },
        desciplineConditional: {
          descipline: {
            "ui:widget": (props) => {
              return <CustomSelect getProps={props} />;
            },
          },
          otherDescipline: {
            "ui:widget": (props) => {
              return <CustomTextBox getProps={props} />;
            },
          },
        },

        nameOfQualification: {
          "ui:widget": (props) => {
            return <CustomSelect getProps={props} />;
          },
        },
        dateObtained: {
          "ui:widget": (props) => {
            return <CustomDate getProps={props} />;
          },
        },
        descipline: {
          "ui:widget": (props) => {
            return <CustomSelect getProps={props} />;
          },
        },
        country: {
          "ui:widget": (props) => {
            return <CustomSelect getProps={props} />;
          },
        },
        degreeCertificate: {
          "ui:widget": (props) => {
            return <CustomUpload getProps={props} />;
          },
        },
      },
    },
  },
  divisionRegistration: {
    divisions: {
      "ui:widget": (props) => {
        return <MultipleSelect getProps={props} />;
      },
    },
  },

  supplementaryDocuments: {
    idOrPassportDoc: {
      "ui:widget": (props) => {
        return <CustomUpload getProps={props} />;
      },
    },
    proofOfRegistration: {
      "ui:widget": (props) => {
        return <CustomUpload getProps={props} />;
      },
    },
    addAnotherProofOfRegistration: {
      items: {
        proofOfRegistration: {
          "ui:widget": (props) => {
            return <CustomUpload getProps={props} />;
          },
        },
      },
    },
  },
  codeOfEthicsLink: {
    "ui:widget": (props) => {
      return (
        <a href={props.schema.uri} target="_blank">
          {props.label}
        </a>
      );
    },
  },
  constitutionAndBylawsLink: {
    "ui:widget": (props) => {
      console.log("constitutionAndBylawsLink", props);
      return (
        <a href={props.schema.uri} target="_blank">
          {props.label}
        </a>
      );
    },
  },
  iHaveReadAllInfo: {
    "ui:widget": (props) => {
      return <CustomCheckBox getProps={props} />;
    },
  },
  privacyInfo: {
    "ui:widget": (props) => {
      return <CustomCheckBox getProps={props} />;
    },
  },
  noteInfo: {
    "ui:widget": (props) => {
      return <p>{props.schema.title}</p>;
    },
  },
  currentDate: {
    "ui:widget": (props) => {
      return <p>{props.schema.title + ": " + new Date().toDateString()}</p>;
    },
  },
};
